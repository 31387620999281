import { FC, Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@ariessolutionsio/primitives-richproducts';
import { Configurator } from '@ariessolutionsio/providers-richproducts';
import { useIncrementDecrement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/IncrementDecrementSelector';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { setCookie } from 'cookies-next';
import { fetchApiHub } from 'frontastic';
import Image from '../../../../frontastic/lib/image';
import { useAccount, useCart } from '../../../../frontastic/provider';
import { useSignInDialog } from '../../../../hooks/dialogs/auth/sign-in';
import { useQueryClient } from '@tanstack/react-query';
import { clearAllLocalData, clearSession } from '@/frontastic/lib/utils/FrontasticSessionData';

export const Accordion: FC<{
  items: any[];
}> = ({ items }) => {
  return (
    <>
      {Object.keys(items).map((key) => (
        <Disclosure key={key}>
          {({ open }) => (
            <>
              <div>
                <Disclosure.Button className="group relative flex w-full items-center  text-left">
                  <span className={cn('text-primary-800', 'text-[12px]')}>{items[key].name}</span>
                  <span className="ml-1 flex items-center">
                    {open ? (
                      <ChevronUpIcon
                        className="block h-4 w-4 text-primary-800 group-hover:text-primary-800"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="block h-4 w-4 text-primary-800 group-hover:text-primary-800"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </Disclosure.Button>
              </div>
              <Disclosure.Panel>
                <div>{items[key].component}</div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export const ProductTilesTastic = () => {
  const queryClient = useQueryClient();
  const { removeItem, addItem, updateItem, data: order } = useCart();
  const { push } = useRouter();
  const { loggedIn, login, requestConfirmationEmail } = useAccount();
  const signInDialog = useSignInDialog({
    title: 'Sign In',
    onSignIn: async (values) => {
      try {
        const response = await login(values.username, values.password);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          setCookie('asio_session', JSON.stringify(res));

          Configurator.get().setSession({
            refreshToken: res.refreshToken,
            accessToken: res.token,
          });
        });

        if (response.accountId) {
          signInDialog.close();
          return true;
        } else if (response.infoError) {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          queryClient.removeQueries({ queryKey: ['get:account'] });
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
    requestConfirmationEmail: async (values) => {
      try {
        const response = await requestConfirmationEmail(values.username, values.password);
        if (response.success) {
          push('/my-account/success-email-verification-pending');
          return true;
        } else if (response.infoError) {
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
  });

  const ProductTile = ({ item }, key) => {
    const { IncrementDecrement, selectedQty } = useIncrementDecrement({ defaultValue: item.count });
    const [, setCurrentValue] = useState(selectedQty);

    useEffect(() => {
      setCurrentValue((prevState) => {
        if (prevState !== selectedQty) {
          if (selectedQty === 0 || Number.isNaN(selectedQty)) {
            updateItem(item.lineItemId, 1);
          } else {
            updateItem(item.lineItemId, selectedQty);
          }
        }
        return selectedQty;
      });
    }, [selectedQty]);

    return (
      <div key={key} className="px-5 py-3.5 xs:mb-3 xs:bg-white lg:mb-0 lg:flex lg:justify-between lg:bg-transparent">
        <div className="flex items-start lg:justify-between">
          <a href={item._url} className="min-w-fit">
            <Image
              src={item.variant?.images[0]}
              alt={item.variant?.imagesAltText?.[0] || item.name}
              className="mr-3 h-[75px] w-[75px] object-cover"
            />
          </a>
          <div className="mr-auto text-sm lg:w-80">
            <p className="text-sm lg:text-xl lg:font-semibold">{item.name}</p>
            <p className="text-xs lg:hidden">Quantity: {item.count}</p>
            {item.variant?.attributes?.cookie_flavor || item.variant?.attributes?.tin_color ? (
              <div className="text-xs lg:hidden">
                <Accordion
                  items={[
                    {
                      id: '1',
                      name: 'Details',
                      component: (
                        <div>
                          {item.variant?.attributes?.cookie_flavor && (
                            <div className="space-x-1">
                              <span>Assortment:</span>
                              <span>{item.variant?.attributes?.cookie_flavor?.label}</span>
                            </div>
                          )}
                          {item.variant?.attributes?.tin_color && (
                            <div className="space-x-1">
                              <span>Tin Color:</span>
                              <span>{item.variant?.attributes?.tin_color?.label}</span>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                ></Accordion>
              </div>
            ) : (
              ''
            )}
            <div className="xs:hidden lg:block">
              <div>
                {item.variant?.attributes?.cookie_flavor && (
                  <div className="space-x-1 text-xs">
                    <span>Assortment:</span>
                    <span>{item.variant?.attributes?.cookie_flavor?.label}</span>
                  </div>
                )}
                {item.variant?.attributes?.tin_color && (
                  <div className="space-x-1 text-xs">
                    <span>Tin Color:</span>
                    <span>{item.variant?.attributes?.tin_color?.label}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <a href="#" className="remove-item-button lg:hidden " onClick={() => removeItem(item.lineItemId)}>
              <Image src="/assets/svg/trashCan.svg" alt="trash can icon" className="lg:hidden" />
            </a>
          </div>
        </div>
        <div className="mt-3.5 flex items-baseline justify-between text-right lg:ml-4 lg:mt-0">
          <div className="mr-4 lg:mr-6">
            <IncrementDecrement />
            <DisplayPrice
              price={item.price}
              component={({ displayPrice }) => (
                <div className="mt-1 text-center xs:hidden lg:mt-2 lg:block lg:text-sm">
                  {displayPrice} <span className="text-sm"> / each</span>
                </div>
              )}
            />
          </div>
          <div className="lg:m-auto lg:flex lg:h-full lg:min-w-[72px] lg:flex-col lg:justify-around">
            <DisplayPrice
              price={item.totalPrice}
              component={({ displayPrice }) => (
                <div className="lg:text-xl lg:font-semibold">
                  <span className="mr-5 lg:hidden">Total</span>
                  <span>{displayPrice}</span>
                </div>
              )}
            />
            <a href="#" className="remove-item-button xs:hidden lg:block" onClick={() => removeItem(item.lineItemId)}>
              <Image src="/assets/svg/trashCan.svg" alt="trash can icon" className="mr-1 inline-block" />
              <span className="text-xs text-primary-800">Remove Item</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-6 pt-6 lg:py-0 lg:pl-16 lg:pr-8">
      {order && order.lineItems?.length > 0 && (
        <div>
          <div className="mt-2.5 font-['Akshar'] text-[24px] font-bold lg:mt-14 lg:text-[36px]">Shopping Cart</div>
          {!loggedIn && (
            <div>
              Want to Send to Multiple Addresses?{' '}
              <Button
                className="m-0 p-0 text-[16px] text-primary-800"
                onClick={() => signInDialog.open()}
                variant="link"
              >
                Sign in
              </Button>{' '}
              or
              <a href="/my-account/create" className="text-primary-800">
                {' '}
                create an account
              </a>
              !
            </div>
          )}
          <div className="mt-7 md:mt-9">
            {order.lineItems.map((item, index) => (
              <Fragment key={index}>
                <ProductTile item={item} />
                <hr className="border-t-1 border-gray-300 xs:hidden lg:block" />
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
