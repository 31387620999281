import Image from 'frontastic/lib/image';

function ImageContainer({ data }) {
  return (
    <div className="flex place-content-center">
      <Image src={data.image.media.file} alt={data.image.title} />
    </div>
  );
}

export default ImageContainer;
