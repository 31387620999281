import { useEffect, useState, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout-form';
import { useCart } from '@/frontastic/provider';

export const Payment = () => {
  const [elementOptions, setElementOptions] = useState(null);
  const cart = useCart() as any;
  const stripePromise = useRef<Stripe | null>(null);
  const totalPrice = cart.data?.taxedPrice?.totalGross || cart.data.sum;

  useEffect(() => {
    (async () => {
      stripePromise.current = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      setElementOptions({
        stripe: stripePromise.current,
      });
    })();
  }, []);

  return (
    <div className="mt-8">
      {elementOptions && (
        <Elements
          stripe={elementOptions.stripe}
          options={{
            mode: 'payment',
            amount: totalPrice.centAmount,
            currency: 'usd',
            externalPaymentMethodTypes:
              process.env.NEXT_STRIPE_ALLOW_PAYPALPAYMENT === 'true' ? ['external_paypal'] : [],
          }}
        >
          <CheckoutForm stripe={elementOptions.stripe} />
        </Elements>
      )}
    </div>
  );
};
