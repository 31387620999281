import { FC } from 'react';
import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { Discount } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/Discount';
import { UseCart } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/UseCart';
import { Money } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Money';

export interface CartFooterProps {
  sum: Money;
  cart: UseCart;
  discountCodes?: Discount[];
}
export const CartFooter: FC<CartFooterProps> = (props) => {
  const { sum, cart, discountCodes } = props;

  const getSubTotal = () => {
    return cart.data?.lineItems?.reduce((a, b) => a + b.variant.price.centAmount * b.count, 0);
  };

  const estimatedSubtotal =
    cart.data && cart.data.lineItems
      ? cart.data.lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0)
      : 0;
  const discountAmount = discountCodes
    ? discountCodes.reduce((acc, item) => (acc += item.discountedAmount?.centAmount), 0)
    : 0;

  if (!cart.data) return <div></div>;
  return (
    <div>
      <hr className="border border-t-0 border-[#454348] opacity-50" />
      <div className="px-8 py-7">
        {cart.data?.lineItems?.length !== 0 && (
          <>
            <section className="flex justify-between">
              <div>Item Subtotal:</div>
              <DisplayPrice
                price={{
                  centAmount: getSubTotal(),
                  currencyCode: 'USD',
                  fractionDigits: 2,
                }}
                component={(displayPrice) => <div className="text-base lg:text-sm">{displayPrice.displayPrice}</div>}
              />
            </section>
            {discountCodes && discountCodes?.length !== 0 && (
              <section className="flex justify-between">
                <div>
                  Discount:{' '}
                  <span>{discountCodes[0]?.code === 'IMPLICIT' ? discountCodes[0]?.name : discountCodes[0]?.code}</span>
                </div>
                <DisplayPrice
                  price={discountCodes[0]?.discountedAmount}
                  component={(displayPrice) => (
                    <div className="text-base text-primary-800 lg:text-sm">- {displayPrice.displayPrice}</div>
                  )}
                />
              </section>
            )}
            <div className="my-2 flex justify-between">
              <span>Shipping</span>
              <span>Calculated in checkout</span>
            </div>
            <DisplayPrice
              price={{
                centAmount: estimatedSubtotal - discountAmount,
                currencyCode: cart.data.sum ? cart.data.sum.currencyCode : 'USD',
                fractionDigits: cart.data.sum ? cart.data.sum.fractionDigits : 2,
              }}
              component={(displayer) => (
                <div className="mb-9 flex justify-between space-x-3 font-semibold ">
                  <span>Estimated Total</span>
                  <span>{displayer.displayPrice}</span>
                </div>
              )}
            ></DisplayPrice>
          </>
        )}
        <div className="flex items-center justify-between">
          {cart.data?.lineItems?.length !== 0 ? (
            <p className="text-xs">Apply promo codes in the cart.</p>
          ) : (
            <p className="text-xs">{`You don’t have any items in your cart.`}</p>
          )}

          <LinkButton palette="primary" href="/cart" size="md" className="flex-none">
            View Cart
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
