/* eslint-disable tailwindcss/no-arbitrary-value */
import { FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { DropdownMenu } from '@ariessolutionsio/primitives-richproducts';
import { Configurator } from '@ariessolutionsio/providers-richproducts';
import { NavigationMenu } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/organisms/NavigationMenu';
import { LineItem } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/LineItem';
import { HeaderMenuProps } from '@ariessolutionsio/react-ecomm-ui/dist/types/frontastic';
import { Money } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Money';
import { useQueryClient } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import { useSignInDialog } from 'hooks/dialogs/auth/sign-in';
import { fetchApiHub } from 'frontastic';
import { clearAllLocalData, clearSession } from 'frontastic/lib/utils/FrontasticSessionData';
import { useAccount } from 'frontastic/provider';
import { CartFooter } from './cart-footer';
import { CartHeader } from './cart-header';
import { closeOutIcon } from './close-icon';
import { ChevronDownIcon, SearchIcon, ShoppingBagIcon, UserIcon } from './icons';
import { ProductTiles } from './product-tiles';
import { useGetAccount } from '@/hooks/my-account/use-get-account';

export interface HeaderProps extends HeaderMenuProps {
  calloutLink?: string;
  onSignInButtonClick(): void;
}

export const Header: FC<HeaderProps> = (props) => {
  const queryClient = useQueryClient();
  const { data } = useGetAccount();
  const { push, asPath, query } = useRouter();
  const cart = props.data.useCart;
  const { login, requestConfirmationEmail } = useAccount();
  const [isOpen, setIsOpen] = useState(false);

  const [menuSelected, setMenuSelected] = useState('');

  const signInDialog = useSignInDialog({
    title: 'Sign In to Your Account',
    onSignIn: async (values) => {
      try {
        const response = await login(values.username, values.password);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          setCookie('asio_session', JSON.stringify(res));

          Configurator.get().setSession({
            refreshToken: res.refreshToken,
            accessToken: res.token,
          });
        });

        if (response.accountId) {
          queryClient.invalidateQueries({ queryKey: ['get:account'] });
          signInDialog.close();
          return true;
        } else if (response.infoError) {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          queryClient.removeQueries({ queryKey: ['get:account'] });

          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
    requestConfirmationEmail: async (values) => {
      try {
        const response = await requestConfirmationEmail(values.username, values.password);

        if (response.success) {
          push('/my-account/success-email-verification-pending');
          return true;
        } else if (response.infoError) {
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
  });

  useEffect(() => {
    const menu = sessionStorage.getItem('menuSelected');
    if (menu) {
      setMenuSelected(menu);
    }
  }, []);

  useEffect(() => {
    if (query?.bootLogin) {
      signInDialog.open();
      push('/');
    }
  }, [asPath]);

  const handleSignOut = async () => {
    await clearSession('frontastic-session');
    await clearAllLocalData();
    await cart.clearCartAndSession();

    setTimeout(async () => {
      await push('/');
      window.location.reload();
    });
  };

  const handleMenuSelected = (menu: string) => {
    sessionStorage.setItem('menuSelected', menu);
    setMenuSelected(menu);
  };

  return (
    <div className="h-[60px] lg:h-[112px]">
      <NavigationMenu
        {...props}
        onMenuSelected={handleMenuSelected}
        userSection={(onClose: () => void) =>
          data?.email ? (
            <>
              <div className="hidden lg:block">
                <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                  <DropdownMenu.Trigger asChild>
                    <button
                      className="group relative -m-2 flex items-center justify-center space-x-2 p-2"
                      onClick={() => signInDialog.open()}
                    >
                      {UserIcon}
                      <span className="ml-2 flex h-full items-center whitespace-nowrap text-center align-middle text-xs font-medium text-gray-700 hover:text-gray-800">
                        Hi, {data.firstName ?? data.email}
                      </span>
                      {ChevronDownIcon}
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content sideOffset={5} className="w-52 rounded-none bg-white p-1 shadow-md">
                    <button className="ml-auto flex pr-3 pt-3" onClick={() => setIsOpen(!isOpen)}>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.17387 4.99929L9.75374 1.42723C9.91051 1.27044 9.99858 1.05779 9.99858 0.836054C9.99858 0.614319 9.91051 0.401665 9.75374 0.244875C9.59697 0.0880841 9.38435 0 9.16264 0C8.94094 0 8.72832 0.0880841 8.57155 0.244875L5 3.82526L1.42845 0.244875C1.27168 0.0880841 1.05906 -1.65206e-09 0.837356 0C0.615652 1.65206e-09 0.403029 0.0880841 0.246261 0.244875C0.0894923 0.401665 0.0014208 0.614319 0.00142079 0.836054C0.00142079 1.05779 0.0894923 1.27044 0.246261 1.42723L3.82613 4.99929L0.246261 8.57135C0.168229 8.64875 0.106294 8.74084 0.0640274 8.84231C0.021761 8.94377 0 9.05261 0 9.16253C0 9.27244 0.021761 9.38128 0.0640274 9.48274C0.106294 9.58421 0.168229 9.6763 0.246261 9.7537C0.323655 9.83175 0.415733 9.89369 0.517185 9.93596C0.618636 9.97824 0.727452 10 0.837356 10C0.94726 10 1.05608 9.97824 1.15753 9.93596C1.25898 9.89369 1.35106 9.83175 1.42845 9.7537L5 6.17332L8.57155 9.7537C8.64894 9.83175 8.74102 9.89369 8.84247 9.93596C8.94392 9.97824 9.05274 10 9.16264 10C9.27255 10 9.38136 9.97824 9.48281 9.93596C9.58427 9.89369 9.67634 9.83175 9.75374 9.7537C9.83177 9.6763 9.89371 9.58421 9.93597 9.48274C9.97824 9.38128 10 9.27244 10 9.16253C10 9.05261 9.97824 8.94377 9.93597 8.84231C9.89371 8.74084 9.83177 8.64875 9.75374 8.57135L6.17387 4.99929Z"
                          fill="#69676D"
                        />
                      </svg>
                    </button>
                    <div className="cursor-pointer">
                      <DropdownMenu.Item onClick={() => push('/my-account')}>My Account</DropdownMenu.Item>
                      <DropdownMenu.Item onClick={() => push('/my-account/orders')}>Order History</DropdownMenu.Item>
                      <DropdownMenu.Item onClick={() => push('my-account/addresses')}>Address Book</DropdownMenu.Item>
                    </div>
                    <DropdownMenu.Separator className="mx-2 bg-gray-200" />
                    <DropdownMenu.Item onClick={handleSignOut} className="my-2 cursor-pointer">
                      Sign Out
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu>
              </div>
              <div className="block lg:hidden">
                <ul>
                  <li className="flex whitespace-nowrap py-3 text-center align-middle text-sm font-medium text-gray-700 hover:text-gray-800">
                    {UserIcon}
                    <span className="my-auto ml-2 flex items-center whitespace-nowrap text-center align-middle text-xs font-medium text-gray-700 hover:text-gray-800">
                      {' '}
                      Hi, {data.firstName ?? data.email}
                    </span>
                  </li>
                  {/* Just placeholders, will be mapped with real data */}
                  <li
                    className="py-2 text-xs font-medium text-gray-700 hover:text-gray-800"
                    onClick={() => {
                      push('/my-account');
                      onClose();
                    }}
                  >
                    My Account
                  </li>
                  <li
                    className="py-2 text-xs font-medium text-gray-700 hover:text-gray-800"
                    onClick={() => {
                      push('/my-account/orders');
                      onClose();
                    }}
                  >
                    Order History
                  </li>
                  <li
                    className="py-2 text-xs font-medium text-gray-700 hover:text-gray-800"
                    onClick={() => {
                      push('my-account/addresses');
                      onClose();
                    }}
                  >
                    Address Book
                  </li>
                  <li
                    className="py-2 text-xs font-medium text-gray-700 hover:text-gray-800"
                    onClick={() => {
                      handleSignOut();
                      onClose();
                    }}
                  >
                    Sign Out
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <button
              className="group relative -m-2 flex items-center justify-center p-2"
              onClick={() => {
                onClose();
                signInDialog.open();
              }}
            >
              {UserIcon}
              <span className="ml-2 flex h-full items-center whitespace-nowrap text-center align-middle text-xs font-medium text-gray-700 hover:text-gray-800">
                Sign In
              </span>
            </button>
          )
        }
        extend={{
          icons: {
            user: UserIcon,
            search: SearchIcon,
            cart: ShoppingBagIcon,
            close: closeOutIcon,
          },
          allowSearch: true,
          cartDrawer: {
            cartHeader: <CartHeader cart={cart} />,
            productTiles: <ProductTiles items={cart.data?.lineItems as LineItem[]} cart={cart} />,
            cartFooter: (
              <CartFooter sum={cart.data?.sum as Money} cart={cart} discountCodes={cart.data?.discountCodes} />
            ),
          },
        }}
      />
    </div>
  );
};
