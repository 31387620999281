import { FC } from 'react';
import {
  CategoryLandingBanner,
  CategoryLandingBannerProps,
} from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/landing-sections/category-landing-banner';

export const CategoryLandingPageBanner: FC<CategoryLandingBannerProps> = (props) => {
  return (
    <div>
      <CategoryLandingBanner
        data={{
          title: props.data.title,
          description: props.data.description,
          background_desktop: props.data.background_desktop,
          background_mobile: props.data.background_mobile,
        }}
      />
    </div>
  );
};
