// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ShipSpeedDescription } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/Ecommerce/checkout/ship-speed-description';
import { Checkbox, Label, Markdown, RadioGroup as RadioG } from '@ariessolutionsio/primitives-richproducts';
import { Address, Configurator } from '@ariessolutionsio/providers-richproducts';
import { Form, useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { ContactInfoForm } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/contact-info';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { LineShippingMethod } from '@Types/cart/CustomShippingMethod';
import { setCookie } from 'cookies-next';
import moment from 'moment';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { fetchApiHub } from 'frontastic';
import { useCart, useAccount, useOrder } from 'frontastic/provider';
import CustomLoader from 'frontastic/provider/loading/CustomLoader';
import { useLoading } from 'frontastic/provider/loading/LoadingProvider';
import { DateByProduct } from './multishipping/date-by-product';
import { MultiShippingAddressForm } from './multishipping/multi-shipping-address-form';
import { SpeedByProduct } from './multishipping/speed-by-product';
import { ShippingInformation } from './shipping-information';
import { SingleShippingAddressForm } from './single-shipping-address-form';
import { parseISO8601Pattern } from '../../../../../helpers/utils/parseISO8601Pattern';
import { useSignInDialog } from '../../../../../hooks/dialogs/auth/sign-in';
import { useRecipientsBookDialog } from '../../../../../hooks/dialogs/recipients-book/recipients-book';
import { OrderDeliveryItem, OrderShippingPlanning, useCheckoutContext } from '../../contexts/checkout.context';
import useCheckoutLSData from '../../hooks/useCheckoutLSData';
import { shippingSchema } from '../../schemas/shipping.schema';
import { ShippingDate } from '../shipping-date';
import { clearAllLocalData, clearSession } from '@/frontastic/lib/utils/FrontasticSessionData';

// This function is used to clean the unnecessary characters from the string for first name and last name
const cleanUnnecesaryChars = (value: string) => (value ? value.replace(/[^a-z,^A-Z,]/g, ' ') : '');

export const ShippingDetails = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { checkoutSLData, updateCheckoutData } = useCheckoutLSData();
  const [
    {
      deliveryMethods,
      provinces,
      checkoutData,
      shipSpeedData,
      shippingInfo,
      unavailabledDates,
      beyondTodayDate,
      undatePattern,
      shippingMode,
      orderDelivery,
      allowShippingSpeedByProduct,
      allowShippingDateByProduct,
      shippingDateSpeed,
    },
    {
      nextStep,
      prevStep,
      setCheckoutData,
      setShippingMode,
      setAllowShippingSpeedByProduct,
      setOrderDelivery,
      setAllowShippingDateByProduct,
      setShippingDateSpeed,
    },
  ] = useCheckoutContext();
  const cart = useCart();
  const { login, requestConfirmationEmail, loggedIn, account: meData } = useAccount();
  const { checkOrderForOneTimeDiscountCode } = useOrder();
  // const [shippingDateSpeed, setShippingDateSpeed] = useState('standard');
  const [loading, setLoading] = useState(false);
  const [usedPromo, setUsedPromo] = useState(false);
  const { push } = useRouter();
  const { setGlobalLoading } = useLoading();

  const signInDialog = useSignInDialog({
    title: 'Sign In',
    onSignIn: async (values) => {
      try {
        const response = await login(values.username, values.password);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          setCookie('asio_session', JSON.stringify(res));

          Configurator.get().setSession({
            refreshToken: res.refreshToken,
            accessToken: res.token,
          });
        });

        if (response.accountId) {
          signInDialog.close();
          return true;
        } else if (response.infoError) {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          queryClient.removeQueries({ queryKey: ['get:account'] });
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
    requestConfirmationEmail: async (values) => {
      try {
        const response = await requestConfirmationEmail(values.username, values.password);
        if (response.success) {
          push('/my-account/success-email-verification-pending');
          return true;
        } else if (response.infoError) {
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
  });

  const fromDate = moment().add(beyondTodayDate, 'days').format('YYYY-MM-DD');
  const undatePatternDates = undatePattern.map((date) => parseISO8601Pattern(date.unavaiableDatesPatter)).flat();
  const disabledDates = unavailabledDates.map((date) => {
    return {
      startDate: moment(date.unavailableDate).format('YYYY-MM-DD'),
      endDate: moment(date.unavailableDate).format('YYYY-MM-DD'),
    };
  });

  const unavailableDates =
    beyondTodayDate > 0
      ? [
          {
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(fromDate).add(1, 'years').format('YYYY-MM-DD'),
          },
          {
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          },
          {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
          },
        ]
      : [
          {
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          },
          {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
          },
        ];

  const noShipDates = [...disabledDates, ...undatePatternDates, ...unavailableDates];

  const findNextAvailableShippingDate = (arr) => {
    const isDateInNoShipDates = (date) => {
      return arr.some((noShipDate) => {
        return (
          date === noShipDate.startDate ||
          date === noShipDate.endDate ||
          (date > noShipDate.startDate && date < noShipDate.endDate)
        );
      });
    };

    const tomorrow = moment().add(1, 'days');
    let nextAvailableShippingDate = tomorrow;
    while (isDateInNoShipDates(nextAvailableShippingDate.format('YYYY-MM-DD'))) {
      nextAvailableShippingDate = nextAvailableShippingDate.add(1, 'days');
    }
    return nextAvailableShippingDate.format('YYYY-MM-DD');
  };

  const nextAvailableShippingDate = {
    startDate: findNextAvailableShippingDate(noShipDates),
    endDate: findNextAvailableShippingDate(noShipDates),
  };

  const [shippingDate, setShippingDate] = useState(nextAvailableShippingDate);

  const schemaForMS = Yup.object().shape({
    contactInfo: shippingSchema.fields.contactInfo,
    giftMessageCheckbox: shippingSchema.fields.giftMessageCheckbox,
    giftMessages: shippingSchema.fields.giftMessages,
  });

  const form = useForm({
    resolver: yupResolver(shippingMode === 'Single Address' ? shippingSchema : schemaForMS),
    defaultValues: {
      contactInfo: checkoutData?.shipping?.contactInfo ?? undefined,
      shipping: checkoutData?.shipping?.shipping ?? undefined,
      shipSpeed: checkoutData?.shipping?.shipSpeed ?? undefined,
      shippingDate: checkoutData?.shipping?.shippingDate ?? undefined,
    },
  });

  const { reset, setValue, formState } = form;
  const { isDirty, isValid, errors } = formState;

  const [defaultShippingAddress, setDefaultShippingAddress] = useState<Address | any | undefined>(
    checkoutData?.shipping?.shipping
      ? {
          ...checkoutData?.shipping?.shipping,
          streetName: checkoutData?.shipping?.shipping?.streetAddress,
          postalCode: checkoutData?.shipping?.shipping?.zipCode,
          apartment: checkoutData?.shipping?.shipping?.additionalStreetInfo,
          additionalStreetInfo: checkoutData?.shipping?.shipping?.additionalStreetInfo,
        }
      : null,
  );
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);

  const RecipientsBookDialog = useRecipientsBookDialog({
    title: 'Select Recipients',
    states: provinces,
    onSingleSelect: (address) => {
      setDefaultShippingAddress({ ...address, addressKey: address.id });
    },
  });

  /** --------- use Effect Section  --------- */
  useEffect(() => {
    const fn = async () => {
      if (!defaultShippingAddress) {
        const defaultAddress = meData.addresses.find((a) => a.id === meData.defaultShippingAddressId);
        setDefaultShippingAddress(defaultAddress);
      }
    };

    if (loggedIn && meData) {
      fn();
    }
  }, [loggedIn, meData]);

  useEffect(() => {
    if (showAddressForm) {
      reset({
        ...form.getValues(),
        shipping: {
          firstName: '',
          lastName: '',
          companyName: '',
          company: '',
          apartment: '',
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          addressKey: defaultShippingAddress?.id ?? defaultShippingAddress?.addressKey,
        },
      });
    } else if (defaultShippingAddress && meData) {
      reset({
        ...form.getValues(),
        contactInfo: {
          email: meData?.email,
          phoneNumber: meData?.metadata.phoneNumber.includes('-')
            ? meData?.metadata.phoneNumber
            : meData?.metadata.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
        },
        shipping: {
          addressKey: defaultShippingAddress?.id ?? defaultShippingAddress?.addressKey,
          firstName: cleanUnnecesaryChars(defaultShippingAddress?.firstName),
          lastName: cleanUnnecesaryChars(defaultShippingAddress?.lastName),
          companyName: defaultShippingAddress?.company,
          streetAddress: `${defaultShippingAddress?.streetName} ${defaultShippingAddress?.streetNumber ?? ''}`,
          city: defaultShippingAddress?.city,
          state: defaultShippingAddress?.state,
          zipCode: defaultShippingAddress?.postalCode,
          apartment: defaultShippingAddress?.additionalStreetInfo,
        },
      });
    }
  }, [defaultShippingAddress, meData, showAddressForm]);

  useEffect(() => {
    window['cc_data_layer'] = {
      cartData: cart.data,
    };
  }, []);

  /** --------- End use Effect Section  --------- */

  useMemo(() => {
    if (checkoutSLData) {
      setTimeout(() => {
        reset(checkoutSLData);
        setShippingDate({
          ...shippingDate,
          startDate: nextAvailableShippingDate.startDate,
          endDate: nextAvailableShippingDate.startDate,
        });
        setShippingDateSpeed((checkoutSLData as any).shippingDateSpeed);
      }, 1000);
    }
  }, [checkoutSLData]);

  const updateSelectedDate = (value, ignoreDateState = false) => {
    if (!ignoreDateState) {
      setShippingDate(value);
    }
    setValue('shippingDate', value);
  };

  /**
   * The function checks if the total quantity to be shipped matches the total quantity placed for all
   * products in the order delivery.
   * @returns The function `allProductsHasAddressDestination` returns a boolean value indicating whether
   * the total quantity to be shipped (`_qtyToBeShipped`) is equal to the total quantity placed
   * (`_qtyPlaced`).
   */
  const allProductsHasAddressDestination = () => {
    const _qtyToBeShipped = orderDelivery.map((od) => od.quantity).reduce((a, b) => a + b, 0);

    const _qtyPlaced = orderDelivery
      .map((od) => od.shippingPlanning)
      .map((sp) => sp.map((sp) => sp.quantity).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0);

    return _qtyToBeShipped === _qtyPlaced;
  };

  const productsWithoutShipDate = () => {
    return orderDelivery
      .map((od) => od.shippingPlanning.map((sp) => sp.shippingDate))
      .flat()
      .filter((sd) => !sd).length;
  };

  const onSubmitMultiPart = {
    addCustomerInformation: async (values: FieldValues) => {
      const customerResponse = (await cart.addCustomerInformation({
        emailAddress: values.contactInfo.email,
        phoneNumber: values.contactInfo.phoneNumber.replaceAll('-', ''),
      })) as any;

      if (customerResponse.infoError) throw new Error(customerResponse.message ?? customerResponse.details);
    },
    setCartAddressForMultiShipping: async (values: FieldValues, isCheckShippingCost: boolean) => {
      const finalOrderDelivery: LineShippingMethod[] = [];

      const _qtyToBeShipped = orderDelivery.map((od) => od.quantity).reduce((a, b) => a + b, 0);

      const _qtyPlaced = orderDelivery
        .map((od) => od.shippingPlanning)
        .map((sp) => sp.map((sp) => sp.quantity).reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0);

      if (_qtyToBeShipped !== _qtyPlaced) {
        toast({
          title: `There are still products (${
            _qtyToBeShipped - _qtyPlaced
          }) without specifying the destination address.`,
        });
        setLoading(false);
        return false;
      }

      if (!isCheckShippingCost && allowShippingDateByProduct && productsWithoutShipDate() > 0) {
        toast({
          title: `There are still products (${productsWithoutShipDate()}) without specifying the shipping date.`,
        });
        setLoading(false);
        return false;
      }

      for (const i in orderDelivery) {
        const _item = orderDelivery[i];
        for (const j in _item.shippingPlanning) {
          const _target = _item.shippingPlanning[j];
          const _defaultShippingMethod = _target.shippingMethod || 'standard';
          _target.shippingDate =
            allowShippingDateByProduct && _target.shippingDate ? _target.shippingDate : shippingDate.startDate;
          _target.shippingMethod = allowShippingSpeedByProduct ? _defaultShippingMethod : values?.shipSpeed?.id;
          _item.shippingPlanning[j] = _target;
        }
        orderDelivery[i] = _item;
      }

      const shippingAddressArray = [];
      for (const i in orderDelivery) {
        const orderItem: OrderDeliveryItem = orderDelivery[i];
        for (const j in orderItem.shippingPlanning) {
          const itemOSP: OrderShippingPlanning = orderItem.shippingPlanning[j];
          shippingAddressArray.push({
            firstName: itemOSP.address.firstName,
            lastName: itemOSP.address.lastName,
            street: itemOSP.address.streetName,
            city: itemOSP.address.city,
            subPremise: itemOSP.address?.additionalStreetInfo,
            state: itemOSP.address.state,
            zipCode: itemOSP.address.postalCode,
            addressKey: itemOSP.address.id,
            company: itemOSP.address?.company,
          });
        }
      }
      const shippingAddressResult = await cart.addShippingAddresses(shippingAddressArray);

      for (const i in orderDelivery) {
        const orderItem: OrderDeliveryItem = orderDelivery[i];
        for (const j in orderItem.shippingPlanning) {
          const itemOSP: OrderShippingPlanning = orderItem.shippingPlanning[j];
          const _addressInCart = shippingAddressResult?.shippingAddress?.find(
            (a) => a.externalId === itemOSP.address.id,
          );
          finalOrderDelivery.push({
            addressKey: _addressInCart.addressId,
            shippingMethod: itemOSP.shippingMethod || 'standard', //values?.shipSpeed?.id,
            shipByDate:
              allowShippingDateByProduct && itemOSP.shippingDate ? itemOSP.shippingDate : shippingDate.startDate,
            quantity: itemOSP.quantity,
            itemId: orderItem.lineItem.lineItemId,
            giftMessage: '',
          });

          itemOSP.address.key = _addressInCart.addressId;
          orderItem.shippingPlanning[j] = itemOSP;
        }
        orderDelivery[i] = orderItem;
      }
      setOrderDelivery([...orderDelivery]);
      try {
        const shippingResult = (await cart.setMultiShippingMethod(finalOrderDelivery)) as any;
        if (shippingResult?.infoError) {
          toast({
            title: 'Unable to set shipping method. Please try again.',
            description: shippingResult.message ?? shippingResult.details,
          });
          setLoading(false);
          return;
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
        toast({
          title: 'Unable to set shipping method. Please try again.',
        });
      }
      return true;
    },
  };

  const CheckOrderByEmailPhoneNumberDiscountCode = async (values) => {
    const orderPayload = {
      email: values.contactInfo?.email,
      phoneNumber: values.contactInfo?.phoneNumber.replace(/-/g, ''),
      discountCodeId: cart.data?.discountCodes[0]?.discountId,
    };
    try {
      const orderResult = await checkOrderForOneTimeDiscountCode(orderPayload);
      if (orderResult.length > 0) {
        toast({
          title:
            'The promo code entered on the previous page is for one-time use only per customer. Please click on the "Previous" button to try another promo code.',
        });
        setLoading(false);
        setUsedPromo(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitMultiShipping = async (values: FieldValues, { isCheckShippingCost }) => {
    if (cart.data?.discountCodes[0]?.discountId) CheckOrderByEmailPhoneNumberDiscountCode(values);

    try {
      if (!allProductsHasAddressDestination) {
        toast({
          title: 'Please check the Ship Speed for your products',
        });
        return;
      }

      if (!shippingDate?.startDate || shippingDateSpeed === 'standard') {
        setShippingDate(nextAvailableShippingDate);
        values = {
          ...values,
          shippingDate: nextAvailableShippingDate,
        };
      }
      let hasNotError = true;
      setLoading(true);
      setGlobalLoading(true);

      await onSubmitMultiPart.addCustomerInformation(values);
      hasNotError = await onSubmitMultiPart.setCartAddressForMultiShipping(values, isCheckShippingCost);

      if (checkoutData) {
        setCheckoutData({
          ...checkoutData,
          shipping: {
            ...values,
            ...checkoutData.shipping,
          },
        });
      } else {
        setCheckoutData({
          shipping: {
            ...values,
            shipping: {
              ...values.shipping,
              company: values.shipping?.company ?? values.shipping?.companyName ?? undefined,
            },
          },
        });
      }
      setGlobalLoading(false);
      setLoading(false);

      if (!isCheckShippingCost && hasNotError) {
        nextStep();
      }
    } catch (error) {
      console.error('error while setting shipping method', error);
      toast({
        title: 'Unable to set shipping method. Please try again.',
      });
      setGlobalLoading(false);
      setLoading(false);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
    }
  };

  const onSubmit = async (values: FieldValues, { isCheckShippingCost }) => {
    if (cart.data?.discountCodes[0]?.discountId) CheckOrderByEmailPhoneNumberDiscountCode(values);

    if (!values.shipSpeed?.id) {
      toast({
        title: 'Please select the Ship Speed',
      });
      return;
    }

    try {
      setGlobalLoading(true);
      setLoading(true);

      if (!shippingDate?.startDate || shippingDateSpeed === 'standard') {
        setShippingDate(nextAvailableShippingDate);
        values = {
          ...values,
          shippingDate: nextAvailableShippingDate,
        };
      }

      const customerResponse = (await cart.addCustomerInformation({
        emailAddress: values.contactInfo.email,
        phoneNumber: values.contactInfo.phoneNumber.replaceAll('-', ''),
      })) as any;

      if (customerResponse.infoError) {
        toast({
          title: 'Unable to add Customer Information',
          description: customerResponse.message ?? customerResponse.details,
        });
        setLoading(false);
        return;
      }

      let sameAddressForBoth = {
        firstName: values.shipping.firstName,
        lastName: values.shipping.lastName,
        street: values.shipping.streetAddress,
        company: values.shipping?.company ?? values.shipping?.companyName ?? undefined,
        subPremise: values.shipping?.apartment,
        city: values.shipping.city,
        state: values.shipping.state,
        zipCode: values.shipping.zipCode,
        addressKey: values.shipping?.addressKey,
      };

      if (loggedIn) {
        sameAddressForBoth = {
          ...sameAddressForBoth,
        };
      }

      const addressKey = await cart.addShippingAddresses([sameAddressForBoth]);
      if (loggedIn) {
        sameAddressForBoth = {
          ...sameAddressForBoth,
        };
      }

      await cart.addBillingAddress({
        ...sameAddressForBoth,
      });

      const shippingPayload = {
        addressKey: addressKey?.shippingAddress[0]?.addressId,
        shippingMethod: values?.shipSpeed?.id,
        shipByDate: values?.shippingDate?.startDate?.split('T')[0],
      } as any;

      const shippingResult = (await cart.setCartShippingMethod(shippingPayload)) as any;

      updateCheckoutData({
        ...checkoutSLData,
        ...values,
        shipping: {
          ...values.shipping,
          company: values.shipping?.company ?? values.shipping?.companyName ?? undefined,
        },
        shippingDateSpeed,
      });

      if (shippingResult?.infoError) {
        toast({
          title: 'Unable to set shipping method. Please try again.',
          description: shippingResult.message ?? shippingResult.details,
        });
        setLoading(false);
        return;
      }

      setCheckoutData({
        shipping: {
          ...values,
          shipping: {
            ...values.shipping,
            company: values.shipping?.company ?? values.shipping?.companyName ?? undefined,
          },
        },
        addressKey: sameAddressForBoth?.addressKey,
      });

      setGlobalLoading(false);
      if (!isCheckShippingCost) {
        nextStep();
      }
    } catch (error) {
      console.error('error while setting shipping method', error);
      toast({
        title: 'Unable to set shipping method. Please try again.',
      });
      setLoading(false);
      setGlobalLoading(false);
    } finally {
      setLoading(false);
      setGlobalLoading(false);
    }
  };

  const triggerSubmitOnChange = async () => {
    if (loading) {
      return;
    }
    const allValues = form.getValues();
    await form.trigger();

    const hasContactInfo = Boolean(
      allValues.contactInfo?.email &&
        allValues.contactInfo?.email !== '' &&
        allValues.contactInfo?.phoneNumber &&
        allValues.contactInfo?.phoneNumber !== '',
    );

    try {
      setGlobalLoading(true);

      if (
        (shippingMode === 'Single Address' && !form.formState.isValid) ||
        (shippingMode === 'Multiple Addresses' && !hasContactInfo)
      ) {
        toast({
          title: 'Form validation error',
          description: 'Please provide the valid information to check shipping cost',
        });

        return;
      }

      if (shippingMode === 'Multiple Addresses') {
        await onSubmitMultiShipping(allValues, { isCheckShippingCost: true });
      }
      if (shippingMode === 'Single Address' && form.formState.isValid) {
        await onSubmit(allValues, { isCheckShippingCost: true });
      }

      setGlobalLoading(false);
    } catch (error) {
      toast({
        title: 'Something went wrong',
      });
    } finally {
      setGlobalLoading(false);
    }
  };

  const selectedState = form.watch('shipping.state');
  const currentShippingMethod = form.watch('shipSpeed');

  if (
    (selectedState === 'AK' || selectedState === 'HI') &&
    (currentShippingMethod as { id: string })?.id === 'standard'
  ) {
    form.setValue('shipSpeed', deliveryMethods[1] as any);
  }

  const checkboxToggle = () => {
    setShowAddressForm(!showAddressForm);
  };

  const getShipingMethodPriceForMultiShipping = (shippingMethod) => {
    const availableMethods = cart.data.availableShippingMethods;

    const price = availableMethods.shippingByLines
      .map((l) => l.shippingByAddressKey.reduce((acc, val) => (acc += val.price[shippingMethod]), 0))
      .reduce((acc, val) => (acc += val), 0);

    return parseFloat(price).toFixed(2);
  };

  const { globalLoading } = useLoading();

  return (
    <Form
      className="mt-8"
      title="My Info & Shipping Details"
      onSubmit={form.handleSubmit((values) =>
        shippingMode === 'Multiple Addresses'
          ? onSubmitMultiShipping(values, { isCheckShippingCost: false })
          : onSubmit(values, { isCheckShippingCost: false }),
      )}
      actions={
        <nav className="flex w-full justify-end space-x-4 py-8">
          <Button variant="text" className="text-black text-sm font-semibold" onClick={prevStep} palette="none">
            Previous
          </Button>
          <Button
            type="submit"
            disabled={loading || usedPromo}
            className={cn(loading || usedPromo ? 'cursor-not-allowed bg-primary-300' : '', 'add-shipping-info-button')}
          >
            {loading ? 'Loading...' : 'Next'}
          </Button>
        </nav>
      }
    >
      <div className="grid lg:grid-cols-2">
        <ContactInfoForm title="My Contact Info" form={form} />
        <ShippingInformation />
      </div>
      <div className="relative">
        <h3 className="text-xl font-bold text-gray-900">Ship to</h3>
        <div className="my-4 flex h-auto w-full flex-col flex-wrap items-start space-y-3 p-4 pl-0 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0">
          <RadioG
            defaultValue="Single Address"
            value={shippingMode}
            className="flex flex-col flex-wrap space-y-1 lg:flex-row lg:space-x-6 lg:space-y-0"
            onValueChange={(value) => {
              //setAddressType(value);
              if (loggedIn) {
                setShippingMode(value as any);
                //setAllowShippingSpeedByProduct(value === 'Multiple Addresses');
              }
            }}
          >
            <div className="flex flex-row items-center space-x-2 text-gray-900">
              <RadioG.Item className="border-gray-900 text-gray-900" value="Single Address" id="r1"></RadioG.Item>
              <Label htmlFor="r1" className="flex flex-row items-center">
                <UserIcon className="mr-1 h-6 w-6 text-gray-900" aria-hidden="true" />
                <p
                  className={
                    shippingMode === 'Single Address'
                      ? 'text-[16px] font-bold text-gray-900'
                      : 'text-[16px] text-gray-900'
                  }
                >
                  Single Address
                </p>
              </Label>
            </div>
            <div className="flex flex-row items-center space-x-2 text-gray-900">
              <RadioG.Item className="border-gray-900 text-gray-900" value="Multiple Addresses" id="r2"></RadioG.Item>
              <Label htmlFor="r2" className="flex flex-row items-center">
                <UserGroupIcon className="mr-1 h-6 w-6 text-gray-900" aria-hidden="true" />
                <p
                  className={
                    shippingMode === 'Multiple Addresses'
                      ? 'text-[16px] font-bold text-gray-900'
                      : 'text-[16px] text-gray-900'
                  }
                >
                  Multiple Addresses
                </p>
              </Label>
            </div>
          </RadioG>
          {!loggedIn && (
            <div className="flex space-x-2">
              <div
                className=" m-0 cursor-pointer p-0 text-[16px] capitalize text-primary-800 hover:bg-transparent hover:text-primary-800 hover:underline"
                onClick={() => signInDialog.open()}
              >
                Sign in
              </div>{' '}
              <div>or</div>
              <a href="/signup" className="text-primary-800">
                {' '}
                Create Account
              </a>
            </div>
          )}
        </div>

        {loggedIn && shippingMode === 'Single Address' && defaultShippingAddress && meData && (
          <div className="my-7 flex h-auto flex-wrap justify-between bg-cream-100 p-4 align-middle">
            <div className="flex gap-4">
              <Checkbox checked={!showAddressForm} onClick={() => checkboxToggle()} className="mt-1" />
              <div>
                <p className="font-bold">{`${defaultShippingAddress?.firstName} ${defaultShippingAddress?.lastName}`}</p>
                <p>{`${defaultShippingAddress?.company ?? ''}`}</p>
                <p>{`${defaultShippingAddress?.streetNumber ?? ''} ${defaultShippingAddress?.streetName}`}</p>
                <p>{`${defaultShippingAddress?.additionalStreetInfo ?? defaultShippingAddress?.apartment ?? ''}`}</p>
                <p>{`${defaultShippingAddress?.city}, ${defaultShippingAddress?.state} ${defaultShippingAddress?.postalCode}`}</p>
              </div>
            </div>

            <Button
              type="button"
              variant="text"
              onClick={() => RecipientsBookDialog.open()}
              className="mx-7 my-4 h-[48px] bg-black-100 px-4 py-2 text-xs font-semibold uppercase text-white lg:mx-3"
            >
              Add/Edit Address
            </Button>
          </div>
        )}

        {shippingMode === 'Single Address' ? (
          (showAddressForm || !loggedIn || (loggedIn && defaultShippingAddress === undefined)) && (
            <SingleShippingAddressForm form={form} provinces={provinces} />
          )
        ) : (
          <MultiShippingAddressForm provinces={provinces} />
        )}
      </div>

      {/* Section Speed Shipping methods*/}
      <section>
        <div className="flex justify-between lg:w-5/6">
          <div className='mb-8 font-["Akshar"] text-[20px] font-bold '>Ship Speed</div>

          <div>
            {loggedIn && shippingMode === 'Multiple Addresses' && (
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="speed"
                  checked={allowShippingSpeedByProduct}
                  onClick={() => {
                    if (!allProductsHasAddressDestination()) {
                      toast({
                        title: `Please adjust recipients or item quantity`,
                      });
                    } else {
                      setAllowShippingSpeedByProduct(!allowShippingSpeedByProduct);
                    }
                  }}
                />
                <Typography
                  variant="body"
                  tagName="div"
                  className={`${shippingMode !== 'Multiple Addresses' && 'text-gray-500'}`}
                >
                  Select unique shipping speeds for each product
                </Typography>
              </div>
            )}
          </div>
        </div>
        {allowShippingSpeedByProduct &&
          shippingMode === 'Multiple Addresses' &&
          orderDelivery.map((orderDeliveryItem: OrderDeliveryItem) => (
            <SpeedByProduct
              key={`OP${orderDeliveryItem.lineItem.lineItemId}`}
              orderDeliveryItem={orderDeliveryItem}
              onChange={allProductsHasAddressDestination() ? triggerSubmitOnChange : null}
            />
          ))}
        {((!allowShippingSpeedByProduct && shippingMode === 'Multiple Addresses') ||
          (shippingMode === 'Single Address' && !allowShippingSpeedByProduct)) && (
          <Form.Section>
            <Controller
              control={form.control}
              name="shipSpeed"
              render={({ field: { onChange, value, name }, formState: { errors } }) => (
                <Form.Control name={name} error={errors?.shipSpeed?.message}>
                  <RadioGroup
                    // defaultValue={deliveryMethods[0]}
                    value={deliveryMethods.find((v) => v.id === (value as { id: string })?.id) ?? undefined}
                    // disabled={loading}
                    onChange={(e) => {
                      onChange(e);
                      triggerSubmitOnChange();
                    }}
                  >
                    <div className="mt-4 space-y-3 ">
                      {deliveryMethods
                        .filter(
                          (method) => method.id !== 'standard' || (selectedState !== 'AK' && selectedState !== 'HI'),
                        )
                        .map((deliveryMethod) => (
                          <RadioGroup.Option
                            key={deliveryMethod.id}
                            value={deliveryMethod}
                            className={({ checked, active }) =>
                              cn(
                                checked ? 'border-transparent' : 'border-gray-300',
                                active ? 'ring-2 ring-primary-500' : '',
                                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none lg:w-5/6',
                                loading ? 'pointer-events-none cursor-not-allowed' : '',
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1 items-center justify-between space-x-3">
                                  {checked ? (
                                    <div className="flex w-1/6 justify-center">
                                      <CheckCircleIcon className="h-5 w-5 text-primary-800" aria-hidden="true" />
                                    </div>
                                  ) : (
                                    <div className="h-5 w-1/6"></div>
                                  )}
                                  <span className="flex w-5/6 flex-col space-y-1">
                                    <RadioGroup.Label>
                                      <span className="block text-sm font-bold text-gray-900">
                                        {
                                          shipSpeedData.find((item: any) => item.methodName === deliveryMethod?.id)
                                            .title
                                        }
                                      </span>
                                    </RadioGroup.Label>
                                    <RadioGroup.Description>
                                      <span className="mt-1 flex items-center text-sm text-gray-500">
                                        <ShipSpeedDescription
                                          methodKey={deliveryMethod?.id}
                                          shipSpeedData={shipSpeedData}
                                        />
                                      </span>
                                    </RadioGroup.Description>
                                  </span>
                                  <RadioGroup.Description className={'w-2/6'}>
                                    <span className="mt-6 text-sm font-semibold text-gray-900">
                                      {shippingMode === 'Multiple Addresses' && globalLoading ? (
                                        <CustomLoader />
                                      ) : (
                                        `$ ${
                                          shippingMode === 'Single Address'
                                            ? deliveryMethod.price
                                            : getShipingMethodPriceForMultiShipping(deliveryMethod.id)
                                        }`
                                      )}
                                    </span>
                                  </RadioGroup.Description>
                                </span>

                                <span
                                  className={cn(
                                    active ? 'border' : 'border-2',
                                    checked ? 'border-primary-500' : 'border-transparent',
                                    'pointer-events-none absolute -inset-px rounded-lg',
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                    </div>
                  </RadioGroup>
                </Form.Control>
              )}
            />
          </Form.Section>
        )}
      </section>
      <div>
        <Markdown className="mt-[-35px]">{shippingInfo}</Markdown>
      </div>

      <section>
        <div className="flex items-start justify-between lg:w-5/6">
          <div className='mb-8 font-["Akshar"] text-[20px] font-bold '>Ship Date</div>
          {loggedIn && shippingMode === 'Multiple Addresses' && shippingDateSpeed !== 'standard' && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="speed"
                checked={allowShippingDateByProduct}
                onClick={() => {
                  if (!allProductsHasAddressDestination()) {
                    toast({
                      title: `Please adjust recipients or item quantity`,
                    });
                  } else {
                    shippingMode === 'Multiple Addresses' && setAllowShippingDateByProduct(!allowShippingDateByProduct);
                  }
                }}
              />
              <Typography
                variant="body"
                setAllowShippingDateByProduct
                tagName="div"
                className={`${shippingMode !== 'Multiple Addresses' && 'text-gray-500'}`}
              >
                Select unique shipping dates for each product
              </Typography>
            </div>
          )}
        </div>

        <Form.Section>
          <div className="my-4 flex h-auto w-full flex-col flex-wrap items-start space-y-3 p-4 pl-0 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0">
            <RadioG
              defaultValue="standard"
              value={shippingDateSpeed}
              className="flex flex-col flex-wrap space-y-1 lg:flex-row lg:space-x-6 lg:space-y-0"
              onValueChange={(value) => setShippingDateSpeed(value)}
            >
              <div className="flex flex-row items-center space-x-2 text-gray-900">
                <RadioG.Item
                  className="border-gray-900 text-gray-900"
                  value="standard"
                  id="radioNextAvailable"
                  onClick={() => updateSelectedDate(nextAvailableShippingDate, true)}
                ></RadioG.Item>
                <Label htmlFor="radioNextAvailable" className="flex cursor-pointer flex-row items-center">
                  <p
                    className={
                      shippingDateSpeed === 'standard'
                        ? 'text-[16px] font-bold text-gray-900'
                        : 'text-[16px] text-gray-900'
                    }
                  >
                    Next Available
                  </p>
                </Label>
              </div>
              <div className="flex flex-row items-center space-x-2 text-gray-900">
                <RadioG.Item
                  className="border-gray-900 text-gray-900"
                  value="schedule"
                  id="radioShipForLater"
                ></RadioG.Item>
                <Label htmlFor="radioShipForLater" className="flex cursor-pointer flex-row items-center">
                  <p
                    className={
                      shippingDateSpeed !== 'standard'
                        ? 'text-[16px] font-bold text-gray-900'
                        : 'text-[16px] text-gray-900'
                    }
                  >
                    Schedule Shipment For Later
                  </p>
                </Label>
              </div>
            </RadioG>
          </div>
          {shippingDateSpeed !== 'standard' && (
            <Controller
              control={form.control}
              name="shippingDate"
              render={({ field: { name }, formState: { errors } }) => (
                <div className="flex flex-col ">
                  {!allowShippingDateByProduct && (
                    <Form.Control name={name} error={errors?.shippingDate?.message}>
                      <ShippingDate
                        value={shippingDate}
                        onChange={updateSelectedDate}
                        unavailabledDates={noShipDates}
                      />
                    </Form.Control>
                  )}
                  {allowShippingDateByProduct &&
                    orderDelivery.map((item) => (
                      <DateByProduct
                        key={`byDate${item.lineItem}`}
                        orderDeliveryItem={item}
                        unavailabledDates={noShipDates}
                      />
                    ))}
                </div>
              )}
            />
          )}
        </Form.Section>
      </section>
    </Form>
  );
};
