import { useEffect, useState } from 'react';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { Form, toast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { AddressForm } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/address';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useCheckoutContext } from '../../../contexts/checkout.context';
import { paymentSchema } from '../../../schemas/payment.schema';
import { AddresBox } from '../address-box/same-address-box';
import { useAccount, useCart } from 'frontastic/provider';

interface CheckoutFormProps {
  stripe: Stripe;
}

const cleanUnnecesaryChars = (value: string) => (value ? value.replace(/[^a-z,^A-Z,]/g, ' ') : '');

export default function CheckoutForm({ stripe }: CheckoutFormProps) {
  const elements = useElements();
  const cart = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [chooseDefaultBillingAddress, setChooseDefaultBillingAddress] = useState<boolean>(true);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState<Address | any | undefined>(undefined);

  const [{ provinces, checkoutData, sameAddress }, { nextStep, prevStep, setPaymentMethodResult, setCheckoutData }] =
    useCheckoutContext();
  const { loggedIn, account: meData } = useAccount();

  const [defaultBillingAddress, setDefaultBillingAddress] = useState<Address | any | undefined>(
    checkoutData.payment
      ? {
          ...checkoutData.payment.billing,
          streetName: checkoutData.payment.billing.streetAddress,
          zipCode: checkoutData.payment.billing.zipCode,
        }
      : undefined,
  );
  const form = useForm({
    resolver: yupResolver(paymentSchema),
    defaultValues: {
      billing: defaultBillingAddress ?? undefined,
    },
  });

  useEffect(() => {
    const fn = async () => {
      const addressesData = meData.addresses;
      const defBillAdr = addressesData.find((a) => a.id === meData.defaultBillingAddressId);
      setDefaultBillingAddress(defBillAdr);
      setDefaultShippingAddress(addressesData.find((a) => a.id === meData.defaultShippingAddressId));
      console.log(defBillAdr);
      form.reset({
        ...form.getValues(),
        billing: {
          ...defBillAdr,
          firstName: cleanUnnecesaryChars(defBillAdr.firstName),
          lastName: cleanUnnecesaryChars(defBillAdr.lastName),
          streetAddress: defBillAdr.streetName,
          zipCode: defBillAdr.postalCode,
        },
      });
    };

    if (loggedIn && meData && !defaultBillingAddress) {
      fn();
    }
  }, [loggedIn, meData]);

  useEffect(() => {
    if (chooseDefaultBillingAddress && checkoutData.shipping.shipping) {
      if (loggedIn) {
        const addressToSet = defaultBillingAddress ||
          defaultShippingAddress || {
            ...checkoutData.shipping.shipping,
            streetName: checkoutData.shipping.shipping.streetAddress,
          };
        form.reset({
          ...form.getValues(),
          billing: {
            firstName: cleanUnnecesaryChars(addressToSet?.firstName) ?? '',
            lastName: cleanUnnecesaryChars(addressToSet?.lastName) ?? '',
            companyName: addressToSet?.company ?? addressToSet?.companyName ?? undefined,
            subPremise: addressToSet.apartment ?? addressToSet?.additionalStreetInfo ?? undefined,
            streetAddress: `${addressToSet?.streetName} ${addressToSet?.streetNumber ?? ''}`,
            city: addressToSet?.city ?? '',
            state: addressToSet?.state ?? '',
            zipCode: addressToSet?.postalCode ?? addressToSet?.zipCode,
            addressKey: addressToSet?.id ?? addressToSet?.addressKey,
          } as any,
        });
      } else {
        form.reset({
          ...form.getValues(),
          billing: checkoutData.shipping.shipping,
        });
      }
    } else if (!chooseDefaultBillingAddress && sameAddress && checkoutData.shipping.shipping) {
      form.reset({
        ...form.getValues(),
        billing: checkoutData.shipping.shipping,
      });
    } else if (defaultBillingAddress && chooseDefaultBillingAddress) {
      form.reset({
        ...form.getValues(),
        billing: {
          firstName: cleanUnnecesaryChars(defaultBillingAddress?.firstName) ?? '',
          lastName: cleanUnnecesaryChars(defaultBillingAddress?.lastName) ?? '',
          companyName: defaultBillingAddress?.company ?? defaultBillingAddress?.companyName ?? undefined,
          subPremise: defaultBillingAddress.apartment ?? defaultBillingAddress?.additionalStreetInfo ?? undefined,
          streetAddress: `${defaultBillingAddress?.streetName} ${defaultBillingAddress?.streetNumber || ''}`,
          city: defaultBillingAddress?.city ?? '',
          state: defaultBillingAddress?.state ?? '',
          zipCode: defaultBillingAddress?.postalCode ?? '',
          addressKey: defaultBillingAddress?.id,
        } as any,
      });
    } else {
      form.reset({
        ...form.getValues(),
        billing: {
          firstName: '',
          lastName: '',
          companyName: '',
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          addressKey: defaultBillingAddress?.id,
        } as any,
      });
    }

    setCheckoutData((prevData) => ({
      ...prevData,
      payment: form.getValues(),
    }));
  }, [form.reset, defaultBillingAddress, defaultShippingAddress, meData, sameAddress, chooseDefaultBillingAddress]);

  const checkboxToggle = () => {
    setChooseDefaultBillingAddress(!chooseDefaultBillingAddress);
  };

  const showShippingAddressToggle: boolean =
    (loggedIn && meData && !chooseDefaultBillingAddress) ||
    !loggedIn ||
    (loggedIn && defaultBillingAddress === undefined);

  const handleSubmit = async (values: FieldValues) => {
    setIsLoading(true);

    try {
      if (!stripe || !elements) {
        return;
      }

      values = {
        ...values,
        billing: {
          ...values.billing,
          apartment: values.billing.apartment ?? values.billing.subPremise ?? undefined,
          company: values.billing?.company ?? values.billing?.companyName ?? undefined,
        },
      };

      const billingAddress = {
        firstName: cleanUnnecesaryChars(values.billing.firstName),
        lastName: cleanUnnecesaryChars(values.billing.lastName),
        street: values.billing.streetAddress,
        company: values.billing?.company ?? values.billing?.companyName ?? undefined,
        subPremise: values.billing.apartment ?? values.billing.subPremise ?? undefined,
        city: values.billing.city,
        state: values.billing.state,
        zipCode: values.billing.zipCode,
        addressKey: sameAddress ? values.billing?.addressKey : undefined,
      };

      await cart.addBillingAddress(billingAddress);

      await elements.submit();

      const { error, confirmationToken } = await stripe.createConfirmationToken({
        elements,
      });

      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          toast({
            title: 'Payment Error',
            description:
              'There was an issue with your method of payment. Please review and try again, use a different payment method, or contact us at orders@christiecookies.com | 1-800-458-2447',
          });
          console.error(error.message);
        } else {
          toast({
            title: 'Payment Error',
            description:
              'There was an issue with your method of payment. Please review and try again, use a different payment method, or contact us at orders@christiecookies.com | 1-800-458-2447',
          });
          console.error('An unexpected error occured.');
        }
        return;
      }

      setCheckoutData((prevData) => ({
        ...prevData,
        payment: {
          ...values,
          confirmationToken: confirmationToken.id,
          paymentPayload: confirmationToken,
        },
      }));

      nextStep();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (form.formState.errors.billing) {
    toast({
      title: 'Error',
      description: Object.keys(form.formState.errors.billing)
        .map((key) => form.formState.errors.billing[key].message)
        .join(' '),
    });
  }

  return (
    <FormProvider {...form}>
      <Form
        className="mt-8"
        title="Payment Details"
        id="payment-form"
        onSubmit={form.handleSubmit(handleSubmit)}
        actions={
          <nav className="flex w-full justify-end space-x-4 py-8">
            <Button variant="text" onClick={prevStep} palette="none" className="font-semibold">
              Previous
            </Button>
            <Button disabled={isLoading} type="submit" id="submit" className=" add-payment-info-button">
              {isLoading ? 'LOADING...' : 'Next'}
            </Button>
          </nav>
        }
      >
        <AddresBox
          checkboxToggle={checkboxToggle}
          chooseDefaultBillingAddress={chooseDefaultBillingAddress}
          defaultBillingAddress={defaultBillingAddress}
          setDefaultBillingAddress={setDefaultBillingAddress}
          meData={meData}
        />
        {!sameAddress && showShippingAddressToggle && (
          <AddressForm form={form} schemaKey="billing" title="Billing Address" states={provinces} />
        )}

        <PaymentElement id="payment-element" />
      </Form>
    </FormProvider>
  );
}
